import React from "react";

import httpClient from "../services/httpClient";
import FormRegisterController from "../components/register/FormController";
import FormBeforeSubmit from "../components/register/FormBeforeSubmit";
import IconBack from "../assets/images/icon_back.png";
import { useDispatch } from "react-redux";
import { updateRegisterData } from "../redux/actions/authAction";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [knowus, setKnowus] = React.useState([]);
  const [bankLists, setBankLists] = React.useState([]);
  const [regData, setRegData] = React.useState({});
  const [pageConfirm, setPageConfirm] = React.useState(false);

  const getData = async () => {
    try {
      const resp1 = await httpClient.get("/authen/getDetailForRegister");
      setKnowus(resp1.data.data.knowus);
      setBankLists(resp1.data.data.bankLists);
    } catch (error) {}
  };

  const updateData = () => {
    const RegisterValue = localStorage.getItem("register");
    if (RegisterValue) {
      dispatch(updateRegisterData(JSON.parse(RegisterValue)));
    }
  };

  React.useEffect(() => {
    if (!localStorage.getItem("phoneNumber")) {
      navigate("/");
    } else {
      const registerdt = JSON.parse(localStorage.getItem("register"));
      if (registerdt) {
        setRegData(registerdt);
      }
      getData();
      updateData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDataFromChild = (data) => {
    data.phoneNumber = localStorage.getItem("phoneNumber");
    data.ref = localStorage.getItem("ref");
    localStorage.setItem("register", JSON.stringify(data));
    setRegData(data);
    setPageConfirm(true);
  };

  const setPageConfirmFalse = () => {
    setPageConfirm(false);
  };

  return (
    <>
      <span
        style={{ cursor: "pointer" }}
        onClick={setPageConfirmFalse}
        className="icon_back"
      >
        <img src={IconBack} alt="IconBack" />
      </span>
      {pageConfirm ? (
        <FormBeforeSubmit
          data={JSON.parse(localStorage.getItem("register"))}
          knowus={knowus}
          bankLists={bankLists}
          onPageConfirm={setPageConfirmFalse}
        />
      ) : (
        <FormRegisterController
          knowus={knowus}
          bankLists={bankLists}
          onDataFromChild={handleDataFromChild}
          registerData={regData}
        />
      )}
    </>
  );
};

export default RegisterPage;
