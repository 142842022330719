import React from "react";
import IconBack from "../../../assets/images/icon_back.png";
import IconTopMark from "../../../assets/images/icon_top_mark.svg";
import BgTitle from "../../../assets/images/bg_title.svg";
import IconCopy from "../../../assets/images/icon_copy.svg";
import { Link } from "react-router-dom";
import httpClient from "../../../services/httpClient";
import toast from "react-hot-toast";
import ContactComponent from "../../../components/ContactComponent";
import { copyToClipboard } from "../../../services/helpers";
import AlertNotify from "../../../components/AlertNotify";

const DepositTrue = () => {
  const [banks, setBanks] = React.useState([]);
  const getBanks = async () => {
    try {
      const resp = await httpClient.get("/main/getAccountForDeposit");
      setBanks(resp.data.data);
    } catch (error) {}
  };

  const formatNumber = (num) => {
    const regex = /^(\d{3})(\d{1})(\d{5})(\d{1})$/;
    return num.replace(regex, "$1-$2-$3-$4");
  };

  const handleCopy = (text) => {
    copyToClipboard(text);
    toast.success(`คัดลอก ${text} สำเร็จ`);
  };

  React.useEffect(() => {
    getBanks();
  }, []);
  return (
    <div>
      <AlertNotify />
      <div className="bg_title bg_title_sm">
        <img src={BgTitle} className="bgSM" alt="BgTitle" />
        <span
          style={{
            whiteSpace: "nowrap",
            fontSize: "1rem",
          }}
        >
          ฝากเงิน true wallet
        </span>
        <Link to="/main/deposit" className="icon_back ic_back_sm">
          <img src={IconBack} alt="IconBack" />
        </Link>
      </div>
      <div
        style={{
          backgroundColor: "rgb(255 255 255 / 40%)",
          color: "red",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.3rem",
        }}
      >
        <p className="p-4">**ฝากผ่านทรูจะไม่ได้รับโปรโมชั่น**</p>
      </div>
      <div className="content_mark text-center">
        <img src={IconTopMark} className="ic_mark" alt="IconTopMark" />
        * ต้องใช้เบอร์ทรูวอลเล็ตที่สมัคร โอนมาเท่านั้น <br />
        * หากไม่ใช่เบอร์ที่สมัคร ระบบจะไม่ทำรายการ <br />
        * ห้ามใช้ บัญชีธนาคารโอนเข้าทรูวอลเล็ต <br />
        * กรณีที่โอนโดยไม่ใช้เบอร์ที่สมัคร ทางเว็บขอไม่ทำรายการ
        และมีค่าธรรมเนียมโอนคืน 20 บาททุกกรณี <br />
        * กรณีโอนมาเพื่อใช้แลกเงินสด โอนแล้วถอนเลย ไม่มีประวัติการเล่นเกม
        จะไม่ทำรายการถอนให้ หรือถ้าโอนกลับจะมีค่าธรรมเนียม 20 บาททุกกรณี
        <br />
        * กรณีโอนผ่านเค้าเตอร์ร้านเซเว่น ให้ติดต่อแอดมิน
        <ContactComponent />
      </div>
      <h6 className="title_backend">ช่องทางการฝากเงิน</h6>
      {banks.map((bank, index) => {
        if (bank.bank_list_id === 24) {
          return (
            <div className="box_Border_Content" key={index}>
              <div className="box_Content box_Content2">
                <div className="bank_title">
                  <div className="bank_name">
                    <h6>{bank.bank_list.bank_name_th} </h6>
                  </div>
                  <div className="bank_icon">
                    <i
                      className={`bank bank-${bank.bank_list.bank_abbrev_en.toLowerCase()} huge`}
                    ></i>
                  </div>
                </div>
                <div className="bank_account">
                  <h5>{bank.name}</h5>
                  <p>
                    เลขที่บัญชี {formatNumber(bank.account_number)}{" "}
                    <a href="#" onClick={() => handleCopy(bank.account_number)}>
                      <img
                        src={IconCopy}
                        className="icon_copy"
                        alt="IconCopy"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default DepositTrue;
