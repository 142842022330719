import React from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../assets/images/icon_close.png";
import httpClient from "../services/httpClient";
import { toast } from "react-hot-toast";

const UnSuccessReport = ({ reports, refreshData }) => {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onRefreshData = async () => {
    refreshData();
  };

  const storeFromUnSuccess = async (reportId) => {
    try {
      await httpClient.post("/main/depositFromUnsuccessReport/" + reportId);
      setShow(false);
      toast.success("เติมเงินเรียบร้อยแล้ว");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      await onRefreshData();
      setLoading(false);
    }
  };

  const preferStore = (reportId) => {
    setLoading(true);
    if (window.confirm("ยืนยันการเติมเงิน?")) {
      storeFromUnSuccess(reportId);
    } else {
      setLoading(false);
      return;
    }
  };
  return (
    <>
      <div className="col-12">
        <div className="input_box">
          <div
            onClick={handleShow}
            className="button_noc"
            style={{
              background: "linear-gradient(rgb(255 153 163), rgb(202 0 19))",
              cursor: "pointer",
            }}
          >
            <span>ยอดฝากค้าง {reports.length} รายการ</span>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <div className="modal-content box_Content box_Content2">
          <div className="icon_close" onClick={handleClose}>
            <img src={IconClose} alt="" />
          </div>
          <div className="title_modal">
            <h5>ยอดฝากค้าง</h5>
          </div>
          <div className="p-3">
            <table className="table table-bordered table-dark">
              <thead>
                <tr>
                  <th>วันที่/เวลา</th>
                  <th>จำนวนเงิน</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {reports.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>
                        <strong className="text-success">{item.amount}</strong>
                      </td>
                      <td>
                        {loading ? (
                          <button className="btn btn-sm btn-success" disabled>
                            กำลังโหลด...
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              preferStore(item.id);
                            }}
                          >
                            เติม
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UnSuccessReport;
