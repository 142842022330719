import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_URL + "/api/v1/frontend",
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    // const { config, response } = error;
    // const originalRequest = config;
    if (response && response.status === 401) {
      localStorage.removeItem("accessToken");
      if (response.request.responseURL.includes("authen/login")) {
        return Promise.reject(error);
      } else {
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);

export default httpClient;
