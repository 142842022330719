import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import httpClient from "../services/httpClient";

const MySwal = withReactContent(Swal);

const AlertNotify = () => {
  const alertMsg = async () => {
    try {
      const resp = await httpClient.get("/main/getAlert");
      console.log(resp.data);
      if (resp.data.status) {
        MySwal.fire("แจ้งเตือน!", resp.data.message, "question");
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    alertMsg();
  }, []);
};

export default AlertNotify;
