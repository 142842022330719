import React from "react";
import httpClient from "../services/httpClient";

const ContactComponent = ({ text = "ติดต่อแอดมิน", button = false }) => {
  const [lineLink, setLineLink] = React.useState("");
  const getData = async () => {
    const resp1 = await httpClient.get("/authen/getNameAndContact");
    setLineLink(resp1.data.lineLink);
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <p>
      {button ? (
        <button className="btn btn-danger">{text}</button>
      ) : (
        <a href={lineLink}>{text}</a>
      )}
    </p>
  );
};

export default ContactComponent;
