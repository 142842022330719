import React, { useEffect } from "react";
import ImageLogo from "../assets/images/logo.svg";
import IconBack from "../assets/images/icon_back.png";
import b3 from "../assets/images/b_3.svg";
import b4 from "../assets/images/b_4.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import httpClient from "../services/httpClient";
import { toast } from "react-hot-toast";
import { Button } from "react-bootstrap";

const OtpPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [otpRef, setOtpRef] = React.useState("");
  const [otp, setOtp] = React.useState("");

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const resp1 = await httpClient.post("/authen/verifyOTP", {
        ref: otpRef,
        otp: otp,
      });
      if (resp1.data.data.isExpire) {
        navigate("/");
      }
      if (resp1.data.data.status) {
        localStorage.setItem("phoneNumber", phoneNumber);
        localStorage.setItem("ref", otpRef);
        if (localStorage.getItem("register")) {
          localStorage.removeItem("register");
        }

        navigate("/register", {
          state: {
            phoneNumber: phoneNumber,
            ref: otpRef,
          },
        });
      }
    } catch (error) {
      toast.error("รหัส OTP ไม่ถูกต้อง");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!location.state || !location.state.phoneNumber || !location.state.ref) {
      navigate("/");
    } else {
      setPhoneNumber(location.state.phoneNumber);
      setOtpRef(location.state.ref);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div>
        <Link to="/" className="icon_back">
          <img src={IconBack} alt="IconBack" />
        </Link>
        <div className="logoTop">
          <img src={ImageLogo} alt="ImageLogo" />
        </div>
        <div className="box_Border_Content">
          <div className="box_Content">
            <span className="img_b3">
              <img src={b3} alt="b3" />
            </span>
            <span className="img_b4">
              <img src={b4} alt="b4" />
            </span>
            <h1 className="title_gx">ใส่รหัส OTP</h1>
            <div className="text-center">
              ใส่รหัส OTP จากข้อความทาง SMS มือถือคุณ
              <p>
                เบอร์โทรศัพท์: <strong>{phoneNumber}</strong>
                <br />
                เลขอ้างอิง Ref: <strong>{otpRef}</strong>
              </p>
            </div>
            <div className="box_from">
              <div className="input_box">
                <input
                  type="text"
                  disabled={loading}
                  onChange={handleChange}
                  className="form-control form-gx text-center"
                  placeholder="ระบุรหัส OTP"
                />
              </div>
              <div className="input_box">
                <div className="d-grid gap-2">
                  {loading ? (
                    <Button disabled variant="primary" className="button_blue">
                      กำลังโหลด...
                    </Button>
                  ) : (
                    <Button onClick={handleSubmit} className="button_blue">
                      ยืนยัน OTP
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <p className="text-center">
              <Link to="/otp">ไม่ได้รับ SMS?</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpPage;
