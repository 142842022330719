import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import b3 from "../assets/images/b_3.svg";
import b4 from "../assets/images/b_4.svg";

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, "C", 0, "D"];

const CreatePin = () => {
  const navigate = useNavigate();
  const [inputValues, setInputValues] = React.useState(["", "", "", ""]);
  const [valuePosition, setValuePosition] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleClick = (number) => {
    if (valuePosition < 4) {
      const newInputValues = [...inputValues];
      newInputValues[valuePosition] = number;
      setInputValues(newInputValues);
      setValuePosition(valuePosition + 1);
    }
  };

  const handleDelete = () => {
    if (valuePosition > 0) {
      const newInputValues = [...inputValues];
      newInputValues[valuePosition - 1] = "";
      setInputValues(newInputValues);
      setValuePosition(valuePosition - 1);
    }
  };

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < numbers.length; i += 3) {
      const rowItems = numbers.slice(i, i + 3);
      const row = (
        <Row key={`row-${i}`} className="mt-3">
          {rowItems.map((number) => (
            <Col key={`col-${number}`}>
              <div className="d-grid gap-2">
                {number === "C" ? (
                  <Button
                    variant="warning"
                    disabled={loading}
                    onClick={() => {
                      setInputValues(["", "", "", ""]);
                      setValuePosition(0);
                    }}
                  >
                    Clear
                  </Button>
                ) : number === "D" ? (
                  <Button
                    variant="warning"
                    disabled={loading}
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    variant="warning"
                    disabled={loading}
                    onClick={() => handleClick(number)}
                  >
                    {number}
                  </Button>
                )}
              </div>
            </Col>
          ))}
        </Row>
      );
      rows.push(row);
    }
    return rows;
  };

  const onSubmit = async () => {
    setLoading(true);
    localStorage.setItem("pin", inputValues.join(""));
    navigate("/confirm-pin");
  };
  React.useEffect(() => {
    if (valuePosition === 4) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);
  return (
    <>
      <div className="logoTop mb-5"></div>
      <div className="box_Border_Content">
        <div className="box_Content">
          <span className="img_b3">
            <img src={b3} alt="" />
          </span>
          <span className="img_b4">
            <img src={b4} alt="" />
          </span>
          <h1 className="title_gx">ใส่รหัส PIN ของคุณ</h1>
          <div className="box_from">
            <div className="input_box pinBox">
              {inputValues.map((inputValue, index) => (
                <input
                  type="text"
                  disabled
                  className="form-control form-gx text-center inputPin"
                  key={index}
                  value={inputValue ? "*" : ""}
                />
              ))}
            </div>
            <div>{renderRows()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePin;
