import React from "react";

import ImageBgTitle from "../../assets/images/bg_title.svg";
import IconBack from "../../assets/images/icon_back.png";
import { Link } from "react-router-dom";
import httpClient from "../../services/httpClient";
import Toast from "react-hot-toast";
import "./test.css";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AlertNotify from "../../components/AlertNotify";

const MySwal = withReactContent(Swal);

const backgroundRounded = {
  backgroundImage:
    "linear-gradient(to right, #051937, #004d7a, #008793, #00bf72, #a8eb12)",
  borderRadius: "10px 10px 10px 10px",
};

const BonusPage = () => {
  const [promotions, setPromotions] = React.useState([]);

  const getData = async () => {
    try {
      const res1 = await httpClient.get("/main/getPromotions");
      const finalRes = res1.data.data.filter(
        (item) => item.name !== "ไม่รับโบนัส"
      );
      setPromotions(finalRes);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storePromotion = async (index) => {
    try {
      const promotion = {
        promotionId: promotions[index].bonus ? 1 : promotions[index].id,
      };
      let txt;
      if (promotion.promotionId === 1) {
        txt =
          '<p style="font-size: 18px">ตั้งค่าเป็น <strong style="color: blue">ไม่รับ</strong> โปรโมชั่นเรียบร้อย</p>';
      } else {
        txt =
          '<p style="font-size: 18px">ตั้งค่าเป็น <strong style="color: blue">รับ</strong> โปรโมชั่นเรียบร้อย</p>';
      }
      await httpClient.post("/main/storePromotions", promotion);
      MySwal.fire({
        title: "เสร็จสิ้น!",
        html: txt,
        icon: "success",
      });
      await getData();
    } catch (error) {
      Toast.error("มีข้อผิดพลาด ไม่สามารถเปลี่ยนโปรโมชั่นได้");
    }
  };

  return (
    <div>
      <AlertNotify />
      <div className="bg_title bg_title_sm">
        <img src={ImageBgTitle} className="bgSM" alt="ImageBgTitle" />
        <span
          style={{
            fontSize: "18px",
            whiteSpace: "nowrap",
          }}
        >
          ตั้งค่ารับโบนัส
        </span>
        <Link to="/main" className="icon_back ic_back_sm">
          <img src={IconBack} alt="IconBack" />
        </Link>
      </div>

      {promotions.map((item, index) => (
        <React.Fragment key={index}>
          <div className="box_Border_Content">
            <div className="box_Content box_Content2">
              <span className="banner_pro">
                <img src={item.image} className="img-fluid" alt="img" />
              </span>
            </div>
          </div>
          <div style={backgroundRounded} className="p-2 round">
            <div className="form-check form-switch ">
              <input
                onClick={() => storePromotion(index)}
                className="form-check-input"
                type="checkbox"
                id="switch"
                defaultChecked={item.bonus}
              />
              <label className="form-check-label" htmlFor="switch">
                ต้องการรับโบนัสหรือไม่?
              </label>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default BonusPage;
