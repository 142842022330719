import React from "react";
import { Button } from "react-bootstrap";
import IconLogo from "../assets/images/logo.svg";
import b3 from "../assets/images/b_3.svg";
import b4 from "../assets/images/b_4.svg";

import httpClient from "../services/httpClient";

import { useForm } from "react-hook-form";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ContactComponent from "../components/ContactComponent";

const IndexPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit } = useForm();

  React.useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/main");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (/^0[1-9]{1}[0-9]{1}[0-9]{3}[0-9]{4}$/.test(data.phoneNumber)) {
        const res = await httpClient.post("/authen/checkPhoneNumber", {
          phoneNumber: data.phoneNumber,
        });
        if (res.data.data.isCustomer) {
          navigate("/pin", { state: { phoneNumber: data.phoneNumber } });
        } else {
          const resp = await httpClient.post("/authen/generateOTP", {
            phoneNumber: data.phoneNumber,
          });
          localStorage.setItem("phoneNumber", data.phoneNumber);
          navigate("/otp", {
            state: {
              phoneNumber: data.phoneNumber,
              ref: resp.data.data,
            },
          });
        }
      } else {
        toast.error("เบอร์โทรศัพท์ไม่ถูกต้อง");
      }
    } catch (error) {
      toast.error("เบอร์โทรศัพท์ไม่ถูกต้อง");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="logoTop">
        <img src={IconLogo} alt="" />
      </div>
      <div className="box_Border_Content">
        <div className="box_Content">
          <span className="img_b3">
            <img src={b3} alt="" />
          </span>
          <span className="img_b4">
            <img src={b4} alt="" />
          </span>
          <h1
            className="title_gx"
            style={{
              fontSize: "1.3rem",
            }}
          >
            เข้าสู่ระบบ/สมัครสมาชิก{" "}
          </h1>
          <div className="text-center"></div>
          <div className="box_from">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input_box">
                <input
                  type="text"
                  {...register("phoneNumber")}
                  className="form-control form-gx text-center"
                  placeholder="กรอกเบอร์มือถือ"
                  disabled={loading}
                />
              </div>
              <div className="input_box">
                <div className="d-grid gap-2">
                  {loading ? (
                    <Button disabled variant="primary" className="button_blue">
                      กำลังโหลด...
                    </Button>
                  ) : (
                    <Button type="submit" className="button_blue">
                      ถัดไป
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="text-center"
        style={{
          marginBottom: "10rem",
        }}
      >
        <ContactComponent />
      </div>
    </>
  );
};

export default IndexPage;
