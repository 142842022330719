import React from "react";

import ImageBgTitle from "../../../assets/images/bg_title.svg";
import IconBack from "../../../assets/images/icon_back.png";
import IconBank from "../../../assets/images/rbbank.png";
import IconTrue from "../../../assets/images/rbtrue.png";
import { Link } from "react-router-dom";
import AlertNotify from "../../../components/AlertNotify";

const DepositIndex = () => {
  const myStyle = {
    width: "100px",
    top: "-40px",
  };
  return (
    <div>
      <AlertNotify />
      <div className="bg_title bg_title_sm">
        <img src={ImageBgTitle} className="bgSM" alt="ImageBgTitle" />
        <span style={{ fontSize: "16px" }}>เลือกระบบการฝากเงิน</span>
        <Link to="/main" className="icon_back ic_back_sm">
          <img src={IconBack} alt="IconBack" />
        </Link>
      </div>
      <div className="row">
        <div className="col-6 mt-4">
          <Link to="/main/deposit/banks" style={{ textDecoration: "none" }}>
            <span className="box_menu">
              <img src={IconBank} alt="" style={myStyle} />
              <strong>ระบบธนาคาร</strong>
            </span>
          </Link>
        </div>
        <div className="col-6 mt-4">
          <Link
            to="/main/deposit/truewallet"
            style={{ textDecoration: "none" }}
          >
            <span className="box_menu">
              <img src={IconTrue} alt="" style={myStyle} />
              <strong>True Wallet</strong>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DepositIndex;
