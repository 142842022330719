import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import b3 from "../assets/images/b_3.svg";
import b4 from "../assets/images/b_4.svg";
import IconBack from "../assets/images/icon_back.png";
import httpClient from "../services/httpClient";
import { toast } from "react-hot-toast";
import ContactComponent from "../components/ContactComponent";

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, "C", 0, "D"];

const PinPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [inputValues, setInputValues] = React.useState(["", "", "", ""]);
  const [valuePosition, setValuePosition] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  React.useEffect(() => {
    if (!location.state || !location.state.phoneNumber) {
      window.location.href = "/";
    }
    setPhoneNumber(location.state.phoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (number) => {
    if (valuePosition < 4) {
      const newInputValues = [...inputValues];
      newInputValues[valuePosition] = number;
      setInputValues(newInputValues);
      setValuePosition(valuePosition + 1);
    }
  };

  const handleDelete = () => {
    if (valuePosition > 0) {
      const newInputValues = [...inputValues];
      newInputValues[valuePosition - 1] = "";
      setInputValues(newInputValues);
      setValuePosition(valuePosition - 1);
    }
  };

  const renderRows = () => {
    const rows = [];
    for (let i = 0; i < numbers.length; i += 3) {
      const rowItems = numbers.slice(i, i + 3);
      const row = (
        <Row key={`row-${i}`} className="mt-3">
          {rowItems.map((number, index) => (
            <Col key={`col-${number}`}>
              <div className="d-grid gap-2">
                {number === "C" ? (
                  <Button
                    variant="warning"
                    disabled={loading}
                    onClick={() => {
                      setInputValues(["", "", "", ""]);
                      setValuePosition(0);
                    }}
                  >
                    Clear
                  </Button>
                ) : number === "D" ? (
                  <Button
                    variant="warning"
                    disabled={loading}
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </Button>
                ) : (
                  <Button
                    variant="warning"
                    disabled={loading}
                    onClick={() => handleClick(number)}
                  >
                    {number}
                  </Button>
                )}
              </div>
            </Col>
          ))}
        </Row>
      );
      rows.push(row);
    }
    return rows;
  };

  const onLogin = async () => {
    setLoading(true);
    try {
      const res = await httpClient.post("/authen/login", {
        phoneNumber: phoneNumber,
        pinCode: inputValues.join(""),
      });
      localStorage.setItem("accessToken", res.data.data.accessToken);
      navigate("/main");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
      setInputValues(["", "", "", ""]);
      setValuePosition(0);
    }
  };

  React.useEffect(() => {
    if (valuePosition === 4) {
      onLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);
  return (
    <>
      <Link to="/" className="icon_back">
        <img src={IconBack} alt="" />
      </Link>
      <div className="logoTop mb-5"></div>
      <div className="box_Border_Content mt-5">
        <div className="box_Content">
          <span className="img_b3">
            <img src={b3} alt="" />
          </span>
          <span className="img_b4">
            <img src={b4} alt="" />
          </span>
          <h1 className="title_gx">กรุณาใส่รหัส PIN Code</h1>
          <div className="box_from">
            <div className="input_box pinBox">
              {inputValues.map((inputValue, index) => (
                <input
                  type="text"
                  disabled
                  className="form-control form-gx text-center inputPin"
                  key={index}
                  value={valuePosition > index ? "*" : ""}
                />
              ))}
            </div>
            <div>{renderRows()}</div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <ContactComponent text="ติดต่อแอดมิน" />
      </div>
    </>
  );
};

export default PinPage;
