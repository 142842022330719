import React from "react";

import ImageB3 from "../../assets/images/b_3.svg";
import ImageB4 from "../../assets/images/b_4.svg";
import ImageBgTitle from "../../assets/images/bg_title.svg";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

const FormRegisterController = ({ knowus, bankLists, onDataFromChild }) => {
  const { register, handleSubmit, setValue } = useForm();
  const onSubmit = async (data) => {
    onDataFromChild(data);
  };
  // const registerData = useSelector((state: any) => state.authReducer.registerData);

  React.useEffect(() => {
    let registerData = localStorage.getItem("register");
    registerData = JSON.parse(registerData);
    if (registerData) {
      setValue("phoneNumber", registerData.phoneNumber);
      setValue("f_name", registerData?.f_name);
      setValue("l_name", registerData?.l_name);
      setValue("line_id", registerData?.line_id);
      setValue("gender_id", registerData.gender_id);
      setValue("knowus_id", registerData.knowus_id);
      setValue("bank_list_id", registerData.bank_list_id);
      setValue("number", registerData.number);
    } else {
      setValue("phoneNumber", localStorage.getItem("phoneNumber"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="bg_title">
        <img src={ImageBgTitle} alt="ImageBgTitle" />
        <span>ข้อมูลส่วนตัว</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="box_Border_Content">
          <div className="box_Content">
            <span className="img_b3">
              <img src={ImageB3} alt="ImageB3" />
            </span>
            <span className="img_b4">
              <img src={ImageB4} alt="ImageB4" />
            </span>
            <h5 className="title_sm">ข้อมูลทั่วไป</h5>
            <div className="box_from">
              <div className="input_box">
                <input
                  type="text"
                  {...register("f_name")}
                  className="form-control form-gx"
                  placeholder="ชื่อ"
                />
              </div>
              <div className="input_box">
                <input
                  type="text"
                  {...register("l_name")}
                  className="form-control form-gx"
                  placeholder="นามสกุล"
                />
              </div>
              <div className="input_box">
                <input
                  type="text"
                  {...register("line_id")}
                  className="form-control form-gx"
                  placeholder="LINE ID (ไม่มีใส่เบอร์มือถือ)"
                />
              </div>
              <div className="input_box">
                <input
                  type="text"
                  disabled
                  className="form-control form-gx"
                  placeholder="เบอร์โทร"
                  {...register("phoneNumber")}
                />
              </div>
              <div className="form-check form-check-inline input_box">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("gender_id")}
                  id="man_check"
                  defaultValue="1"
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="man_check">
                  ชาย
                </label>
              </div>
              <div className="form-check form-check-inline input_box">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("gender_id")}
                  id="women_check"
                  defaultValue="2"
                />
                <label className="form-check-label" htmlFor="women_check">
                  หญิง
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="box_Content">
            <h5 className="title_sm">คุณรู้จักเราจากที่ไหน</h5>
            <div className="box_from">
              <div className="row mb-2">
                {knowus.map((item, index) => {
                  return (
                    <div className="col-md-6 col-6" key={index}>
                      <div className="input_box">
                        <input
                          className="form-check-input"
                          {...register("knowus_id")}
                          type="radio"
                          defaultValue={item.id}
                          id={`radio-${index}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          {item.name}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="box_Border_Content">
          <div className="box_Content">
            <h5 className="title_sm">ข้อมูลบัญชีธนาคาร</h5>
            <div className="box_from">
              <div className="input_box">
                <select
                  className="select7 form-control form-gx"
                  {...register("bank_list_id")}
                  required
                >
                  <option value="">กรุณาเลือกธนาคาร...</option>
                  {bankLists.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.bank_name_th}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="input_box">
                <input
                  type="text"
                  className="form-control form-gx"
                  {...register("number")}
                  placeholder="เลขที่บัญขี"
                  required
                />
              </div>
              <div className="content_foot">
                <small>
                  ข้อมูลบัญชีธนาคารต้องตรงกับชื่อที่สมัครเท่านั้น
                  และเลขบัญชีต้องตรง กับบัญชีจริงเท่านั้นจึงจะทำรายการ
                  ฝาก-ถอนได้ ถ้าหากแจ้งบัญชีชื่อมา ไม่ตรงกับชื่อ-นามสกุล
                  ที่ลงทะเบียน ขอสงวนสิทธิ์ในการถอนทุกกรณี
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="input_box">
          <div className="d-grid gap-2">
            <Button type="submit" className="button_blue">
              สมัครสมาชิก
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormRegisterController;
