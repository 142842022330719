import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ImageBgTitle from "../assets/images/bg_title.svg";
import httpClient from "../services/httpClient";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

const ConfirmPromotion = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [promotion, setPromotion] = React.useState({
    id: null,
    name: null,
    image: null,
    links: null,
  });

  const getData = async () => {
    setLoading(true);
    try {
      const res1 = await httpClient.get("/authen/getPromotion");
      setPromotion(res1.data.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    getData();
  }, []);

  const sendFinal = async (data) => {
    try {
      setLoading(true);
      let aPromotion = null;
      if (data.promotionAccept === "a") {
        aPromotion = promotion.promotion.id;
      } else {
        aPromotion = 1;
      }
      const res1 = await httpClient.post("/authen/register", {
        registerId: localStorage.getItem("registerId"),
        pinCode: localStorage.getItem("pin"),
        pinCode2: localStorage.getItem("pin"),
        promotionId: aPromotion,
      });

      const resp = await httpClient.post("/authen/login", {
        phoneNumber: res1.data.data.phoneNumber,
        pinCode: res1.data.data.pinCode,
      });
      localStorage.setItem("accessToken", resp.data.data.accessToken);
      localStorage.removeItem("registerId");
      localStorage.removeItem("pin");
      navigate("/main");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (data) => {
    if (data.promotionAccept === "a") {
      MySwal.fire({
        title: "โปรสมัครใหม่",
        html:
          "รับโบนัสฝากขั้นต่ำ 100 บาท โบนัสสูงสุด 500 บาท ทำเทิร์น 1 เท่า" +
          'ถอนสูงสุด 3,000 บาท <span style="color: red">เล่นได้เฉพาะสล็อตกับยิงปลาเท่านั้น</span>' +
          " ถ้าหากเล่นผิดกฎคืนเงินเท่ายอดฝากทุกกรณี กรณีที่มีเจตนาซ่อนฟรีสปินยอดทั้งหมดจะเป็นโมฆะ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน!",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await sendFinal(data);
        }
      });
    } else {
      MySwal.fire({
        title: "ไม่รับโบนัส?",
        text: "ไม่รับโบนัส ฝากไม่มีขั้นต่ำ ถอนขั้นต่ำ 100 บาท เล่นได้ทุกเกมส์ไม่จำกัด",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน!",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await sendFinal(data);
        }
      });
    }
  };
  return (
    <div>
      <div className="bg_title bg_title_sm">
        <img src={ImageBgTitle} className="bgSM" alt="ImageBgTitle" />
        <span className="fs-6">เงื่อนไขการถอนและโปรโมชั่น</span>
      </div>
      <div className="box_Border_Content">
        <div className="box_Content box_Content2">
          <img src={promotion.links} className="img-fluid" alt="imag" />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="box_Border_Content">
          <div className="box_Contentmy box_Contentmy2">
            <div className="box_from">
              <div className="form-check form-check-inline input_box">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("promotionAccept")}
                  id="man_check"
                  defaultValue="a"
                />
                <label className="form-check-label" htmlFor="man_check">
                  รับโบนัส
                </label>
              </div>
              <br />
              <div className="form-check form-check-inline input_box mt-2">
                <input
                  className="form-check-input"
                  type="radio"
                  {...register("promotionAccept")}
                  id="women_check"
                  defaultValue="b"
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="women_check">
                  ไม่รับโบนัส
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="d-grid gap-2 mt-2">
          {loading ? (
            <Button disabled className="button_noc">
              กำลังโหลด...
            </Button>
          ) : (
            <Button type="submit" className="button_noc">
              ยืนยัน
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ConfirmPromotion;
