import React from 'react';

import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoutes from './middlewares/protectedRoute'
import IndexPage from './pages/IndexPage';
import BonusPage from './pages/main/BonusPage';
import DepositIndex from './pages/main/deposit/DepositIndex';
import MainIndexPage from './pages/main/MainIndexPage';
import ProfilePage from './pages/main/ProfilePage';
import PromotionPage from './pages/main/PromotionPage';
import ReportPage from './pages/main/ReportPage';
import PinPage from './pages/PinPage';
import DepositBank from './pages/main/deposit/DepositBank';
import DepositTrue from './pages/main/deposit/DepositTrue';
import WithdrawIndex from './pages/main/withdraw/WithdrawIndex';
import OtpPage from './pages/OtpPage';
import RegisterPage from './pages/RegisterPage';
import CreatePinPage from './pages/CreatePin';
import ConfirmPinPage from './pages/ConfirmPinPage';
import ConfirmPromotion from './pages/ConfirmPromotion';

function App() {
  React.useEffect(() => {
    document.body.classList.add("bgMain_2");
  }, []);
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/pin" element={<PinPage />} />
      <Route path="/otp" element={<OtpPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/create-pin" element={<CreatePinPage />} />
      <Route path="/confirm-pin" element={<ConfirmPinPage />} />
      <Route path="/confirm-promotion" element={<ConfirmPromotion />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/main" element={<MainIndexPage />} />
        <Route path="/main/profile" element={<ProfilePage />} />
        <Route path="/main/bonus" element={<BonusPage />} />
        <Route path="/main/report" element={<ReportPage />} />
        <Route path="/main/promotion" element={<PromotionPage />} />
        <Route path="/main/deposit" element={<DepositIndex />} />
        <Route path="/main/deposit/banks" element={<DepositBank />} />
        <Route path="/main/deposit/truewallet" element={<DepositTrue />} />
        <Route path="/main/withdraw" element={<WithdrawIndex />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
