import React from "react";
import ImageTitle from "../../../assets/images/bg_title.svg";
import IconBack from "../../../assets/images/icon_back.png";
import IconTopMark from "../../../assets/images/icon_top_mark.svg";
import { Link, useNavigate } from "react-router-dom";
import httpClient from "../../../services/httpClient";
import { toast } from "react-hot-toast";
import ContactComponent from "../../../components/ContactComponent";
import PinkButton from "../../../assets/images/pinkbutton.png";
import AlertNotify from "../../../components/AlertNotify";

const WithdrawIndex = () => {
  const navigate = useNavigate();
  const [realWithdraw, setRealWithdraw] = React.useState(0);
  const [withdrawLimit, setWithdrawLimit] = React.useState(0);
  const [turnover, setTurnover] = React.useState(0);
  const [withdrawMNM, setWithdrawMNM] = React.useState(0);
  const [balance, setBalance] = React.useState(0);
  const [outstanding, setOutstanding] = React.useState(0);
  const [amount, setAmount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const resp2 = await httpClient.get("/main/getDetailForWithdraw");
      const resp3 = await httpClient.get("/game/getCredit");

      setRealWithdraw(resp2.data.realWithdraw);
      setWithdrawLimit(resp2.data.withdrawLimit);
      if (resp2.data.turnover.status) {
        setTurnover(resp2.data.turnover.turnover);
      }
      setWithdrawMNM(resp2.data.withdrawMNM);
      setBalance(resp3.data.data.balance);
      setOutstanding(resp3.data.data.outstanding);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const acceptNumber = (number) => {
    const x = number.match(/\./g);
    if (x) {
      return false;
    } else {
      return true;
    }
  };

  const handleWithdraw = async () => {
    if (!acceptNumber(amount)) {
      toast.error("กรุณากรอกจำนวนเต็มเท่านั้น");
      return;
    }

    if (amount < withdrawMNM) {
      toast.error(`จำนวนเงินต้องไม่น้อยกว่า ${withdrawMNM}`);
      return;
    }

    if (amount > realWithdraw) {
      toast.error(`จำนวนเงินต้องไม่เกิน ${realWithdraw}`);
      return;
    }

    if (amount > balance) {
      toast.error(`ยอดเงินไม่พอสำหรับถอน`);
      return;
    }

    try {
      await httpClient.post("/main/withdraw", {
        amount,
      });
      navigate("/main/report");
      toast.success("ทำรายการสำเร็จ");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <AlertNotify />
      <div className="bg_title bg_title_sm">
        <img src={ImageTitle} className="bgSM" alt="ImageTitle" />
        <span>ถอนเงิน</span>
        <Link to="/main" className="icon_back ic_back_sm">
          <img src={IconBack} alt="IconBack" />
        </Link>
      </div>
      <div className="bg_title bg_title_sm">
        <img src={PinkButton} className="bgSM" alt="ImageTitle" />
        <span
          style={{
            fontSize: "12px",
          }}
        >
          จำนวนเงินที่สามารถถอนได้ : <br />
          <strong style={{ fontSize: "20px" }}>{balance}</strong>
        </span>
      </div>
      <h6 className="title_backend">จำนวนเงินที่ต้องการถอน</h6>
      <div className="box_Border_Content">
        <div className="box_Content box_Content2">
          <div className="box_from">
            <div className="input_box">
              <input
                type="text"
                className="form-control form-gx text-center text_bb"
                placeholder="จำนวนเงินที่ต้องการถอน"
                onChange={handleAmountChange}
              />
            </div>
            <div className="input_box">
              <div className="d-grid gap-2">
                <button
                  onClick={handleWithdraw}
                  className="button_noc button_purple btn_noc_block"
                >
                  <span>ยืนยันการถอนเงิน</span>
                </button>
              </div>
            </div>
            {loading ? (
              <div className="text-center">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            ) : (
              <ul style={{ fontSize: "18px" }}>
                <li>
                  ยอดเงินที่สามารถถอนได้ต่อวัน :{" "}
                  <strong>
                    {realWithdraw} / {withdrawLimit}
                  </strong>
                </li>
                <li>
                  ยอดเทิร์นโอเวอร์:{" "}
                  <strong>
                    {balance} / {turnover}
                  </strong>
                </li>
                <li>
                  ยอดค้างในเกมส์: <strong>{outstanding}</strong>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="content_mark text-center">
        <img src={IconTopMark} className="ic_mark" alt="IconTopMark" />
        ระบุจำนวนเงินที่ต้องการถอน พบปัญหาในการถอนเงิน หากยอดเงินไม่เข้า
        <ContactComponent />
      </div>
    </div>
  );
};

export default WithdrawIndex;
