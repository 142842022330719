export const UPDATE_REGISTER_DATA = "UPDATE_REGISTER_DATA";

export const updateRegisterData = (registerData) => {
  return {
    type: UPDATE_REGISTER_DATA,
    payload: {
      registerData: registerData,
    },
  };
};
