import React from "react";
import { Link } from "react-router-dom";
import IconBack from "../../assets/images/icon_back.png";
import IconLogo from "../../assets/images/logo.svg";
import IconCopy from "../../assets/images/icon_copy.png";
import IconVerify from "../../assets/images/icon_verify.png";
import httpClient from "../../services/httpClient";
import toast from "react-hot-toast";
import IconClose from "../../assets/images/icon_close.png";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { copyToClipboard } from "../../services/helpers";
import AlertNotify from "../../components/AlertNotify";

const acursor = { cursor: "pointer" };

const ProfilePage = () => {
  const [loading, setLoading] = React.useState(false);
  const [gameUsername, setGameUsername] = React.useState("");
  const [gamePassword, setGamePassword] = React.useState("");
  const [banks, setBanks] = React.useState([]);
  const [fullname, setFullname] = React.useState("");
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { register, handleSubmit } = useForm();

  const getData = async () => {
    setLoading(true);
    try {
      const res1 = await httpClient.get("/main/getProfile");
      setGameUsername(res1.data.data.customer.game_username);
      setGamePassword(res1.data.data.customer.password);
      setBanks(res1.data.data.banks);
      setFullname(
        res1.data.data.customer.f_name + " " + res1.data.data.customer.l_name
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const formatNumber = (num) => {
    const regex = /^(\d{3})(\d{1})(\d{5})(\d{1})$/;
    return num.replace(regex, "$1-$2-$3-$4");
  };

  const handleCopy = (text) => {
    copyToClipboard(text);
    toast.success(`คัดลอก ${text} สำเร็จ`);
  };

  const formatToLowerCase = (text) => {
    return text.toLowerCase();
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await httpClient.post("/main/changePass", {
        password: data.password,
        confirm_password: data.confirm_password,
      });
      await getData();
      toast.success(res.data.message);
      handleClose();
    } catch (error) {
      if (error.response.data.errors.password) {
        toast.error(error.response.data.errors.password[0]);
      } else {
        toast.error(error.response.data.errors.confirm_password[0]);
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <AlertNotify />
      <div>
        <Link to="/main" className="icon_back">
          <img src={IconBack} alt="" />
        </Link>
        <p />
        <div className="box_Border_Content mt-5">
          <div className="box_Content box_Content2 pdContent2">
            <img src={IconLogo} alt="" className="logo_center" />
            <div className="row posRes">
              <div className="col-3 mt-2">
                <label className="label_dark">รหัสผู้ใช้งาน</label>
              </div>
              <div className="col-9">
                <div className="posRes">
                  <div className="button_noc button_yellow input_btb">
                    <span>{gameUsername}</span>
                    <a
                      href={() => false}
                      style={acursor}
                      onClick={() => handleCopy(gameUsername)}
                      className="copy_right"
                    >
                      <img src={IconCopy} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row posRes">
              <div className="col-3 mt-2">
                <label className="label_dark">รหัส Pin</label>
              </div>
              <div className="col-9">
                <div className="posRes">
                  <div className="button_noc button_purple input_btb">
                    <span>{gamePassword}</span>
                    <a
                      href={() => false}
                      style={acursor}
                      onClick={() => handleCopy(gamePassword)}
                      className="copy_right"
                    >
                      <img src={IconCopy} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-right">
              <a href={() => false} style={acursor} onClick={handleShow}>
                เปลี่ยนรหัสผ่าน
              </a>
            </p>
          </div>
        </div>
        <h6 className="title_backend">บัญชีธนาคารของคุณ</h6>
        {banks.map((item, index) => {
          return (
            <div className="box_Border_Content" key={index}>
              <div className="box_Content box_Content2">
                <div className="bank_title">
                  <div className="bank_name">
                    <h6>{item.bank_list.bank_name_th}</h6>
                  </div>
                  <div className="bank_icon">
                    <i
                      className={`bank bank-${formatToLowerCase(
                        item.bank_list.bank_abbrev_en
                      )} huge`}
                    ></i>
                  </div>
                </div>
                <div className="bank_account">
                  <h5>{fullname}</h5>
                  <p>
                    เลขที่บัญชี {formatNumber(item.number)}{" "}
                    <a
                      href={() => false}
                      style={acursor}
                      onClick={() => handleCopy(item.number)}
                    >
                      <img src={IconCopy} alt="" className="icon_copy" />
                    </a>
                  </p>
                </div>
                <div className="verifiy_badge">
                  <span>
                    <img src={IconVerify} alt="" /> ยืนยันตัวตนแล้ว
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <div className="modal-content box_Content box_Content2">
          <a href={() => false} className="icon_close" onClick={handleClose}>
            <img src={IconClose} alt="" />
          </a>
          <div className="title_modal">
            <h5>เปลี่ยนรหัสผ่าน</h5>
          </div>
          <label className="posRes label_dark mb-2">รหัสผ่านเดิม</label>
          <div className="posRes mb-4">
            <div className="button_noc button_purple input_btb">
              <span>{gamePassword}</span>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input_box">
              <input
                type="text"
                {...register("password")}
                className="form-control form-gx"
                placeholder="ใส่รหัสผ่านใหม่"
              />
            </div>
            <div className="input_box">
              <input
                type="text"
                {...register("confirm_password")}
                className="form-control form-gx"
                placeholder="ใส่รหัสผ่านใหม่อีกครั้ง"
              />
            </div>
            <div className="input_box mb-3">
              <div className="d-grid gap-2">
                {loading ? (
                  <Button
                    disabled
                    variant="primary"
                    className="button_noc button_yellow"
                  >
                    กำลังโหลด...
                  </Button>
                ) : (
                  <Button type="submit" className="button_noc button_yellow">
                    ยืนยันการเปลี่ยนรหัสผ่าน
                  </Button>
                )}
              </div>
            </div>
          </form>
          <small className="sm_mark">
            รหัส pin ต้องประกอบด้วยตัวเลข 4 ตัวอักษร
          </small>
        </div>
      </Modal>
    </>
  );
};

export default ProfilePage;
