import React from "react";
import IconLogo from "../../assets/images/logo.svg";
import ImageBgTitle from "../../assets/images/bg_title.svg";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import httpClient from "../../services/httpClient";
import { toast } from "react-hot-toast";

const genders = [
  { id: 1, name: "ชาย" },
  { id: 2, name: "หญิง" },
];

const FormBeforeSubmit = ({ data, knowus, bankLists, onPageConfirm }) => {
  const navigate = useNavigate();
  const [knowusName, setKnowusName] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [genderName, setGenderName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  // const [errors, setErrors] = React.useState([]);
  React.useEffect(() => {
    const foundData = knowus.find((dt) => dt.id === Number(data.knowus_id));
    const foundBank = bankLists.find(
      (dt) => dt.id === Number(data.bank_list_id)
    );
    const foundGender = genders.find((dt) => dt.id === Number(data.gender_id));
    setBankName(
      foundBank?.bank_abbrev_en +
        " : " +
        foundBank.bank_abbrev_th +
        " : " +
        data.number
    );
    setKnowusName(foundData?.name);
    setGenderName(foundGender?.name);
  }, [knowus, bankLists, data]);

  const setPageConfirm = () => {
    onPageConfirm(false);
  };

  const sendBeforePin = async () => {
    setLoading(true);
    try {
      const regisData = JSON.parse(localStorage.getItem("register"));
      const res1 = await httpClient.post("/authen/beforePin", regisData);
      const registerId = res1.data.data;
      localStorage.setItem("registerId", registerId);
      localStorage.removeItem("phoneNumber");
      localStorage.removeItem("register");
      localStorage.removeItem("ref");
      navigate("/create-pin");
    } catch (error) {
      if (error.response.data.errors) {
        toast.error(error.response.data.errors[0].message);
      } else {
        toast.error(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="bg_title">
        <img src={ImageBgTitle} alt="ImageBgTitle" />
        <span>ยืนยันข้อมูล</span>
      </div>
      <div className="box_Border_Content mt-5">
        <div className="box_Content box_Content2 pdContent2">
          <img src={IconLogo} alt="" className="logo_center" />
          <div className="row posRes">
            <div className="col-3">
              <label className="label_dark">ชื่อ-สกุล</label>
            </div>
            <div className="col-9">
              <div className="posRes">
                <div className="button_noc button_purple input_btb">
                  {data.f_name} {data.l_name}
                </div>
              </div>
            </div>
          </div>
          <div className="row posRes">
            <div className="col-3">
              <label className="label_dark">ไอดีไลน์</label>
            </div>
            <div className="col-9">
              <div className="posRes">
                <div className="button_noc button_purple input_btb">
                  {data.line_id}
                </div>
              </div>
            </div>
          </div>
          <div className="row posRes">
            <div className="col-3">
              <label className="label_dark">เบอร์โทร</label>
            </div>
            <div className="col-9">
              <div className="posRes">
                <div className="button_noc button_purple input_btb">
                  {data.phoneNumber}
                </div>
              </div>
            </div>
          </div>
          <div className="row posRes">
            <div className="col-3">
              <label className="label_dark">เพศ</label>
            </div>
            <div className="col-9">
              <div className="posRes">
                <div className="button_noc button_purple input_btb">
                  {genderName}
                </div>
              </div>
            </div>
          </div>
          <div className="row posRes">
            <div className="col-3">
              <label className="label_dark">รู้จักจาก</label>
            </div>
            <div className="col-9">
              <div className="posRes">
                <div className="button_noc button_purple input_btb">
                  {knowusName}
                </div>
              </div>
            </div>
          </div>
          <div className="row posRes">
            <div className="col-3">
              <label className="label_dark">ธนาคาร</label>
            </div>
            <div className="col-9">
              <div className="posRes">
                <div className="button_noc button_purple input_btb">
                  {bankName}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "red",
              backgroundColor: "rgb(255 255 255)",
              padding: "12px",
            }}
          >
            <strong>
              *** ชื่อ-สกุล เดียวกัน สามารถสมัครได้เพียงครั้งเดียวเท่านั้น
              หากตรวจสอบพบว่ามีชื่อซ้ำ ขอสงวนสิทธิ์ในการถอนทุกกรณี
            </strong>
          </div>
          <div className="d-grid gap-2 mt-2">
            {loading ? (
              <Button disabled variant="warning">
                กำลังโหลด...
              </Button>
            ) : (
              <>
                <Button onClick={sendBeforePin} variant="warning">
                  ยืนยัน
                </Button>
                <Button onClick={setPageConfirm} variant="danger">
                  กลับ
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormBeforeSubmit;
