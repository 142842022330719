import React from "react";
import IconBack from "../../../assets/images/icon_back.png";
import IconTopMark from "../../../assets/images/icon_top_mark.svg";
import BgTitle from "../../../assets/images/bg_title.svg";
import IconCopy from "../../../assets/images/icon_copy.svg";
import { Link } from "react-router-dom";
import httpClient from "../../../services/httpClient";
import toast from "react-hot-toast";
import ContactComponent from "../../../components/ContactComponent";
import { copyToClipboard } from "../../../services/helpers";
import AlertNotify from "../../../components/AlertNotify";

const DepositBank = () => {
  const [banks, setBanks] = React.useState([]);
  const getBanks = async () => {
    try {
      const resp = await httpClient.get("/main/getAccountForDeposit");
      const finalRes = resp.data.data.filter(
        (bank) => bank.bank_list_id !== 24
      );
      setBanks(finalRes);
    } catch (error) {}
  };

  const formatNumber = (num) => {
    const regex = /^(\d{3})(\d{1})(\d{5})(\d{1})$/;
    return num.replace(regex, "$1-$2-$3-$4");
  };

  const handleCopy = (text) => {
    copyToClipboard(text);
    toast.success(`คัดลอก ${text} สำเร็จ`);
  };

  React.useEffect(() => {
    getBanks();
  }, []);
  return (
    <div>
      <AlertNotify />
      <div className="bg_title bg_title_sm">
        <img src={BgTitle} className="bgSM" alt="bgtitle" />
        <span>ฝากเงินผ่านธนาคาร</span>
        <Link to="/main/deposit" className="icon_back ic_back_sm">
          <img src={IconBack} alt="IconBack" />
        </Link>
      </div>
      <div className="content_mark text-center">
        <img src={IconTopMark} className="ic_mark" alt="IconTopMark" />
        * ต้องใช้ธนาคารที่ลงทะเบียนโอนมาเท่านั้น <br />
        * ถ้าหากไม่ใช่ธนาคารที่ลงทะเบียนระบบจะไม่ทำรายการ <br />
        * กรณีที่โอนโดยไม่ใช้ธนาคารที่สมัคร ทางเว็บขอไม่ทำรายการทุกกรณี <br />
        ระบบจะเติมอัตโนมัติภายใน 30 วินาที ถ้าหากยอดไม่เข้า กรุณาติดต่อแอดมิน
        <ContactComponent />
      </div>
      <h6 className="title_backend">ช่องทางการฝากเงิน</h6>
      {banks.map((bank, index) => (
        <div className="box_Border_Content" key={index}>
          <div className="box_Content box_Content2">
            <div className="bank_title">
              <div className="bank_name">
                <h6>{bank.bank_list.bank_name_th} </h6>
              </div>
              <div className="bank_icon">
                <i
                  className={`bank bank-${bank.bank_list.bank_abbrev_en.toLowerCase()} huge`}
                ></i>
              </div>
            </div>
            <div className="bank_account">
              <h5>{bank.name}</h5>
              <p>
                เลขที่บัญชี {formatNumber(bank.account_number)}{" "}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={() => handleCopy(bank.account_number)}>
                  <img src={IconCopy} className="icon_copy" alt="IconCopy" />
                </a>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DepositBank;
