import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { Toaster } from "react-hot-toast";

import ScrollToTop from "./scrollToTop";

import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./redux/reducers/index";

const store = createStore(rootReducer);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-6 col-md-10">
        <Provider store={store}>
          <Router>
            <ScrollToTop />
            <App />
          </Router>
          <Toaster />
        </Provider>
      </div>
    </div>
  </div>
);
