import React from "react";
import { Link } from "react-router-dom";
import IconM1 from "../assets/images/icon-m1.svg";
import IconM2 from "../assets/images/icon-m2.svg";
import IconM3 from "../assets/images/icon-m3.svg";
import IconM4 from "../assets/images/icon-m4.svg";
import IconM5 from "../assets/images/icon-m5.svg";
import IconM6 from "../assets/images/icon-m6.svg";
import IconPlay from "../assets/images/icon_play.png";
// import httpClient from "../services/httpClient";
// import ContactComponent from "./ContactComponent";

const MenuComponent = ({ urlPlayGame }) => {
  // const [lineLink, setLineLink] = React.useState("");
  // const getData = async () => {
  //   const resp1 = await httpClient.get("/authen/getNameAndContact");
  //   setLineLink(resp1.data.lineLink);
  // };
  // React.useEffect(() => {
  //   getData();
  // }, []);
  const menus = [
    {
      name: "ข้อมูลผู้ใช้งาน",
      icon: IconM1,
      size: "size_m1",
      link: "/main/profile",
    },
    {
      name: "ตั้งค่ารับโบนัส",
      icon: IconM2,
      size: "size_m2",
      link: "/main/bonus",
    },
    {
      name: "ประวัติฝากถอน",
      icon: IconM3,
      size: "size_m3",
      link: "/main/report",
    },
    {
      name: "เงื่อนไขการถอนและโปรโมชั่น",
      icon: IconM4,
      size: "size_m4",
      link: "/main/promotion",
    },
  ];
  return (
    <>
      <div className="playBox">
        <a
          href={urlPlayGame}
          target="_blank"
          className="play_btn"
          rel="noreferrer"
        >
          <img src={IconPlay} alt="" />
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            เข้าเล่นเกม
          </span>
        </a>
      </div>
      <div className="row">
        {menus.map((menu, index) => {
          return (
            <div className="col-4" key={index}>
              <Link to={menu.link} className="box_menu">
                <img src={menu.icon} alt="" className={menu.size} />
                <span>{menu.name}</span>
              </Link>
            </div>
          );
        })}
        <div className="col-4">
          <a
            href="https://linktr.ee/RUBYCLUB69"
            target="_blank"
            className="box_menu"
            rel="noreferrer"
          >
            <img src={IconM5} alt="" className="size_m3" />
            <span>เกมในเครือข่าย</span>
          </a>
        </div>
        <div className="col-4">
          <a href="https://lin.ee/kdfcpPB" className="box_menu">
            <img src={IconM6} alt="" className="size_m6" />
            <span>ติดต่อเรา</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default MenuComponent;
