import React from "react";
import MenuComponent from "../../components/MenuComponent";
import IconLogo from "../../assets/images/logo.svg";
import BgTitle from "../../assets/images/bg_title2.svg";
import IconWithdraw from "../../assets/images/icon_withdraw.svg";
import IconDeposit from "../../assets/images/icon_deposit.svg";
import b3 from "../../assets/images/b_3.svg";
import b4 from "../../assets/images/b_4.svg";
import IconLogout from "../../assets/images/icon_logout.svg";
import { Link, useNavigate } from "react-router-dom";
import httpClient from "../../services/httpClient";
import { toast } from "react-hot-toast";
import { BiRefresh } from "react-icons/bi";
import UnSuccessReport from "../../components/UnSuccessReport";
import AlertNotify from "../../components/AlertNotify";

const MainIndexPage = () => {
  const navigate = useNavigate();
  const [balance, setBalance] = React.useState(0);
  const [playUrl, setPlayUrl] = React.useState("");
  const [promotion, setPromotion] = React.useState("กำลังโหลด...");
  const [customerName, setCustomerName] = React.useState("กำลังโหลด...");
  const [username, setUsername] = React.useState("กำลังโหลด...");
  const [reports, setReports] = React.useState([]);

  const getData = async () => {
    try {
      const res1 = await httpClient.get("/game/getCredit");
      const res2 = await httpClient.post("/game/playGame");
      const res3 = await httpClient.get("/main/getCurrentPromotion");
      const res4 = await httpClient.get("/main/getProfile");
      const res5 = await httpClient.get("/main/getUnsuccessReport");
      setPromotion(res3.data.data);
      setBalance(res1.data.data.balance);
      setPlayUrl(res2.data.data.url);
      setReports(res5.data.data);
      console.log(res5.data.data);
      setCustomerName(
        res4.data.data.customer.f_name + " " + res4.data.data.customer.l_name
      );
      setUsername(res4.data.data.customer.game_username);
    } catch (error) {}
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    toast.success("ออกจากระบบสำเร็จ");
    navigate("/");
  };

  const handleRefresh = () => {
    setBalance("กำลังโหลด...");
    getData();
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AlertNotify />
      <div className="bg_title2">
        <img src={IconLogo} alt="" className="logo_sm" />
        <img src={BgTitle} alt="" className="img_title2" />
        <span className="name_title">{customerName}</span>
        <span
          className="id_title"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          รหัสผู้ใช้งาน: {username}
        </span>
        <div className="input_box">
          <button
            onClick={() => handleLogout()}
            className="button_blue icon_only"
          >
            <img src={IconLogout} alt="" />
          </button>
        </div>
      </div>
      <div className="box_Border_Content">
        <div className="box_Content box_Content2">
          <span className="img_b3">
            <img src={b3} alt="" />
          </span>
          <span className="img_b4">
            <img src={b4} alt="" />
          </span>
          <div className="titleCredit">
            <div className="row">
              <div className="col-11">
                <h5 className="ml-5">ยอดเงินคงเหลือ</h5>
                <h2 className="ml-1">
                  <span>฿{balance}</span>
                </h2>
              </div>
              <div className="col-1">
                <BiRefresh
                  onClick={() => handleRefresh()}
                  size={25}
                  color={"#fff"}
                  style={{
                    backgroundColor: "rgb(239 50 76)",
                    borderRadius: 10,
                    cursor: "pointer",
                    marginTop: 20,
                    marginRight: 70,
                  }}
                  className="float-end"
                />
              </div>
            </div>
          </div>
          <div className="center_credit">
            <div className="c_credit">
              <span className="title_cr">สถานะโปรโมชั่น</span>
              <span className="mt-2">{promotion}</span>
            </div>
          </div>
          <div className="row">
            {reports.length > 0 ? (
              <UnSuccessReport refreshData={getData} reports={reports} />
            ) : (
              ""
            )}
            <div className="col-6">
              <div className="input_box">
                <Link to="/main/deposit" className="button_noc button_yellow">
                  <span>ฝากเงิน</span>
                  <img
                    src={IconDeposit}
                    alt=""
                    className="icon_bt"
                    style={{
                      width: 25,
                    }}
                  />
                </Link>
              </div>
            </div>
            <div className="col-6">
              <div className="input_box">
                <Link to="/main/withdraw" className="button_noc button_purple">
                  <span>ถอนเงิน</span>
                  <img
                    src={IconWithdraw}
                    alt=""
                    className="icon_bt"
                    style={{ width: 25 }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MenuComponent urlPlayGame={playUrl} />
    </>
  );
};

export default MainIndexPage;
