import React from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import ImageBgTitle from "../../assets/images/bg_title.svg";
import IconBack from "../../assets/images/icon_back.png";
import httpClient from "../../services/httpClient";

const statusType = (type) => {
  switch (type) {
    case 1:
      return <Badge bg="success">สำเร็จ</Badge>;
    case 2:
      return <Badge bg="primary">กำลังทำรายการ</Badge>;
    case 3:
      return <Badge bg="danger">ยกเลิก</Badge>;
    case 4:
      return (
        <span className="badge" style={{ backgroundColor: "rgb(211 133 22)" }}>
          ติดต่อแอดมิน
        </span>
      );
    case 5:
      return <Badge bg="primary">กำลังทำรายการ</Badge>;
    case 6:
      return <Badge bg="info">ฝากค้าง</Badge>;
    case 7:
      return (
        <span className="badge" style={{ backgroundColor: "rgb(211 133 22)" }}>
          ตัดเครดิต
        </span>
      );
    case 8:
      return <Badge bg="danger">เล่นเกมผิดเงื่อนไข</Badge>;
    case 9:
      return <Badge bg="danger">ตัดเครดิตตามเงื่อนไข</Badge>;
    default:
      return <Badge bg="success">สำเร็จ</Badge>;
  }
};

const ReportPage = () => {
  const [reports, setReports] = React.useState([]);
  const getData = async () => {
    try {
      const resp = await httpClient.get("/main/getReport");
      console.log(resp.data);
      setReports(resp.data.data.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="bg_title bg_title_sm">
        <img src={ImageBgTitle} className="bgSM" alt="ImageBgTitle" />
        <span
          style={{
            whiteSpace: "nowrap",
          }}
        >
          ประวัติฝากถอน
        </span>
        <Link to="/main" className="icon_back ic_back_sm">
          <img src={IconBack} alt="IconBack" />
        </Link>
      </div>
      <div className="box_Border_Content mt-5">
        <div className="box_Content box_Content2 pdContent2">
          <h5 className="title_sm">ประวัติฝากถอน 10 รายการล่าสุด</h5>
          <div className="box_from">
            <table className="table table-bordered table-dark">
              <thead>
                <tr>
                  <th>วันที่/เวลา</th>
                  <th>จำนวน</th>
                  <th>โบนัส</th>
                  <th>สถานะ</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.date}</td>
                      <td>
                        {item.type_id === 1 ||
                        item.type_id === 4 ||
                        item.type_id === 6 ||
                        item.type_id === 7 ? (
                          <span className="text-success">{item.amount}</span>
                        ) : (
                          <span className="text-danger">-{item.amount}</span>
                        )}
                      </td>
                      <td>
                        <span className="text-info">{item.bonus}</span>
                      </td>
                      <td>{statusType(item.status_id)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="content_foot">
              <small>
                ข้อมูลบัญชีธนาคารต้องตรงกับชื่อที่สมัครเท่านั้น
                และเลขบัญชีต้องตรง กับบัญชีจริงเท่านั้นจึงจะทำรายการ ฝาก-ถอนได้
                ถ้าหากแจ้งบัญชีชื่อมา ไม่ตรงกับชื่อ-นามสกุล ที่ลงทะเบียน
                ขอสงวนสิทธิ์ในการถอนทุกกรณี
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPage;
