import { UPDATE_REGISTER_DATA } from "../actions/authAction";
const initState = {
  registerData: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_REGISTER_DATA:
      return {
        ...state,
        registerData: action.payload.registerData,
      };
    default:
      return state;
  }
};

export default authReducer;
